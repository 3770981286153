<template>
  <div class="box">
        <el-form
            v-if="model"
            ref="form"
            label-width="100px">
            <el-form-item>
            <h3>{{ pageTitle }}</h3>
            </el-form-item>

            <el-form-item label="赠送优惠劵">
              <el-select v-model="model.coupon_id" placeholder="请选择">
                <el-option
                  v-for="item in coupons"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="文字描述">
              <el-input
              style="width:500px;"
              v-model="model.c_body" placeholder="请输入描述"></el-input>
            </el-form-item>

            <el-form-item label="失效时间">
                <div class="b-card">
                <el-radio-group v-model="model.time_type">
                    <el-radio :label="1">指定日期</el-radio>
                    <el-date-picker
                    v-model="model.time_day"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <el-radio :label="2" style="margin-left:50px;">有效期</el-radio>
                    <el-input style="width:100px;margin-left:20px;" v-model="model.day" placeholder=""></el-input>天
                </el-radio-group>
                </div>
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    size='medium'
                    @click="create"
                >保存</el-button>
            </el-form-item>
        </el-form>
        <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import LabelService from '@admin/services/LabelService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  name: 'ReviewCoupon',
  data () {
    return {
      pageTitle: '点评赠券',
      model: null,
      coupons: null
    }
  },
  components: { PlaceholderForm },
  async created () {
    const { data } = await flatry(LabelService.reviewCoupon())
    if (data) {
      this.coupons = data.data.coupons
      this.model = { ...data.data.model }
    }
  },
  methods: {
    async create () {
      const { data } = await flatry(LabelService.reviewCoupon(this.model))
      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/review/coupon' })
      }
    }
  }

}

</script>
<style lang='' scoped>

</style>
