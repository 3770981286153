import Vue from 'vue'

const LabelService = {
  // 订单备注标签列表
  order (param) {
    return Vue.http.get('label/order', param)
  },
  orderCreate (model = null) {
    const endpoint = 'label/order-create'

    if (model === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, model)
  },

  orderEdit (id, model = null) {
    const endpoint = 'label/order-update'

    if (model === null) {
      return Vue.http.get(endpoint, { id: id })
    }

    return Vue.http.put(endpoint, model, { id: id })
  },
  orderDel (param) {
    return Vue.http.delete('label/order-delete', param)
  },
  // 反馈标签
  feedback (param) {
    return Vue.http.get('label/feedback', param)
  },

  feedbackCreate (model = null) {
    const endpoint = 'label/feedback-create'

    if (model === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, model)
  },
  feedbackDel (param) {
    return Vue.http.delete('label/feedback-delete', param)
  },
  // 点评
  review (param) {
    return Vue.http.get('feedback/review', param)
  },
  reviewDel (param) {
    return Vue.http.delete('feedback/review-del', param)
  },
  // 赠券
  reviewCoupon (model = null) {
    const endpoint = 'feedback/review-coupon'

    if (model === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, model)
  }

}

export default LabelService
